"use strict";

$(function () {
  Fancybox.bind('[data-fancybox="gallery"]', {
    animated: false,
    showClass: false,
    hideClass: false,
    dragToClose: false,
    Image: {
      zoom: false
    },
    Toolbar: {
      display: [{
        id: "counter",
        position: "center"
      }, "close"]
    }
  });
});